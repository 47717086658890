import React, {Component} from 'react';
import Header from './header/Header';
import Content from './main/Content';


class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      objectID: '',
      sortBy: '',
      sort: '',
    }

    this.resetSort = this.resetSort.bind(this);
  }

  // CUSTOM METHODS

  handleSort = (event) => {

    let sortType = event.target.dataset.sortType;
    let sortName = event.target.parentNode.querySelectorAll('p')[0].dataset.sortName;

    this.setState({ 
        sort: sortType,
        sortBy: sortName,
    });

  }

  handleObjID = ({target: {value}}) => {
    this.setState({
      objectID: value,
    }) 
  }

  resetID = () => {
    this.setState({
      objectID: '',
    })
  }

  resetSort = () => {
    this.setState({
      sortBy: '',
      sort: '',
    })
  }

  ////////////////

  render() {

    const {objectID, sortBy, sort} = this.state;
    const {getObjectInfo} = this.props;
    
    return (
      <div className="custom_container">
          <Header objectID={objectID} sort={sort} sortBy={sortBy} handleSort={this.handleSort} handleObjID={this.handleObjID}/>
          <Content resetID={this.resetID} resetSort={this.resetSort} getObjectInfo={getObjectInfo} objectID={objectID} sortBy={sortBy} sort={sort} />         
      </div>
    );
  }
  
}

export default HomePage;
