import React, {Component} from 'react';
import SideFliter from './side_filter/SideFilter';
import ObjectItem from './object_item/ObjectItem';
import Pagination from "react-js-pagination";


import s from './Content.module.css';

class Content extends Component {

    state = {
        objectType: '',
        objects: [],
        response: '',
        floor_from: '',
        floor_to: '',
        max_floor_from: '',
        max_floor_to: '',
        not_first_floor: '0',
        not_last_floor: '0',
        price_for: '1',
        price_from: '',
        price_to: '',
        total_area_from: '',
        total_area_to: '',
        cnt_room_1: '',
        cnt_room_2: '',
        cnt_room_3: '',
        cnt_room_4: '',
        rooms_count: '',
        land_plot_area_from: '',
        land_plot_area_to: '',
        square_value: '',
        release_date_from: '',
        release_date_to: '',
        region_id: '',
        area_id: '',
        city_id: '',
        selectedRegionName: '',
        selectedAreaName: '',
        selectedCityName: '',
        district_id: [],
        microarea_id: [],
        landmark_id: [],
        not_obj: 'not_obj',
        all_region: 0,
        all_area: 0,
        all_city: 0,
        id: '',
        sort_order: '',
        sort_by: '',
        type: '',
        page: 1,
    }

    onChange = (event) => {

        const {name, value} = event.target;

        this.setState({
            ...this.state,
            [name]:value
        })
    }

    onSubmit = (e) => {

        e.preventDefault();
        const {objectID, sort, sortBy} = this.props;
        this.setState({
            id: objectID,
            sort_order: sort,
            sort_by: sortBy,
            type: localStorage.getItem('objectType')
        }, () => {
            const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
            let params = this.state;
            delete params.objects;
            delete params.response;
            url.search = new URLSearchParams(params).toString();

            fetch(url, {
                headers: new Headers({
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                }),
            }).then(res => res.json())
            .then(response => this.setResponse(response))
            .catch(error => console.error('Error:', error));

        })
    }

    resetForm = () => {

        this.setState({
            floor_from: '',
            floor_to: '',
            max_floor_from: '',
            max_floor_to: '',
            not_first_floor: '0',
            not_last_floor: '0',
            price_for: '1',
            price_from: '',
            price_to: '',
            total_area_from: '',
            total_area_to: '',
            cnt_room_1: '',
            cnt_room_2: '',
            cnt_room_3: '',
            cnt_room_4: '',
            rooms_count: '',
            land_plot_area_from: '',
            land_plot_area_to: '',
            square_value: '',
            release_date_from: '',
            release_date_to: '',
            region_id: '',
            area_id: '',
            city_id: '',
            selectedRegionName: '',
            selectedAreaName: '',
            selectedCityName: '',
            district_id: [],
            microarea_id: [],
            landmark_id: [],
            not_obj: 'not_obj',
            all_region: 0,
            all_area: 0,
            all_city: 0,
            page: 1
        }, () => {
            const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
            let params = this.state;
            url.search = new URLSearchParams(params).toString();

            fetch(url, {
                headers: new Headers({
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                }),
            }).then(res => res.json())
            .then(response => this.setResponse(response))
            .catch(error => console.error('Error:', error));

        });
    }

    handleReset = (e) => {

        const {resetID, resetSort} = this.props;

        e.preventDefault();

        this.resetForm();
        resetID();
        resetSort();
        document.getElementById('preview').innerHTML = '';
    }


    componentDidUpdate(prevProps) {
        const {objectID, sort, sortBy} = this.props;

        if(prevProps.objectID !== objectID || prevProps.sort !== sort || prevProps.sortBy !== sortBy) {
            setTimeout(() => {
                document.getElementById('submit').click();
            },500)
        } 

    }

    handleFloors = (e) => {

        const {name} = e.target;

        if( this.state[name] === '1') {
            this.setState({
                [name] : '0'
            })
        } else {
            this.setState({
                [name] : '1'
            })
        }

    }

    handleRooms = (e) => {

        const {name, value} = e.target;

        if( this.state[name] === value) {
            this.setState({
                [name] : ''
            })
        } else {
            this.setState({
                [name] : value
            })
        }

    }

    getMainAddress = (selectedRegion, selectedArea, selectedCity, selectedRegionName, selectedAreaName, selectedCityName, all_region, all_area, all_city) => {
        this.setState({
            region_id: selectedRegion,
            area_id: selectedArea,
            city_id: selectedCity,
            selectedRegionName: selectedRegionName,
            selectedAreaName: selectedAreaName,
            selectedCityName: selectedCityName,
            all_region: all_region,
            all_area: all_area,
            all_city: all_city,
        })
    }

    getSubAddress = (selectedDistricts, selectedMicroareas, selectedLandmarks, not_obj) => {
        this.setState({
            district_id: selectedDistricts,
            microarea_id: selectedMicroareas,
            landmark_id: selectedLandmarks,
            not_obj: not_obj
        })
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    getObjType = ({target: {id}}) => {

        this.setState({
            objectType: id,
            type: id,
            page: 1
        }, () => {
            const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
            let params = this.state;
            url.search = new URLSearchParams(params).toString();

            fetch(url, {
                headers: new Headers({
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                }),
            }).then(res => res.json())
            .then(response => this.setResponse(response))
            .catch(error => console.error('Error:', error));

            localStorage.setItem('objectType', id);

            this.resetForm();

        });
    }

    getObject = (objects) => {
        this.setState({
            ...this.state,objects
        })
    }

    componentDidMount() {

        const localObj = localStorage.getItem('objectType');

        if(localObj === null) {
            this.setState({
                objectType: 'flat',
                type: 'flat',
                page: 1
            },() =>{
                const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
                let params = this.state;
                delete params.objects;
                delete params.response;
                url.search = new URLSearchParams(params).toString();
    
                fetch(url, {
                    headers: new Headers({
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                    }),
                }).then(res => res.json())
                .then(response => this.setResponse(response))
                .catch(error => console.error('Error:', error));
            })
            localStorage.setItem('objectType', 'flat');
        } else {
            this.setState({
                objectType: localObj,
                type: localObj,
                page: 1
            },() => {
                const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
                let params = this.state;
                url.search = new URLSearchParams(params).toString();
    
                fetch(url, {
                    headers: new Headers({
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                    }),
                }).then(res => res.json())
                .then(response => this.setResponse(response))
                .catch(error => console.error('Error:', error));
            })
        }     

    }

    setResponse = response => {
        this.setState({
            response: response,
        })

        this.getObject(response.data);
    }

    handlePageChange = (pageNumber) => {
        this.setState({page: pageNumber},()=>{
            const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
            let params = this.state;
            delete params.objects;
            delete params.response;
            url.search = new URLSearchParams(params).toString();

            fetch(url, {
                headers: new Headers({
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                }),
            }).then(res => res.json())
            .then(response => this.setResponse(response))
            .catch(error => console.error('Error:', error));

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

        });
    }

    render() {

        const {objectType, objects, response} = this.state;
        const {getObjectInfo, objectID, sort, sortBy, resetID, resetSort} = this.props;

        return (
            <>
            <div className={s.main_wrapper}>
                <SideFliter 
                    {...this.state}
                    getMainAddress={this.getMainAddress}
                    getSubAddress={this.getSubAddress}
                    handleRooms={this.handleRooms}
                    handleFloors={this.handleFloors}
                    handleReset={this.handleReset}
                    onSubmit={this.onSubmit}
                    onChange={this.onChange} 
                    getForm={this.getForm} 
                    resetID={resetID} 
                    resetSort={resetSort} 
                    sort={sort} 
                    sortBy={sortBy} 
                    objectID={objectID} 
                    getObject={this.getObject} 
                    getObjType={this.getObjType} 
                />
                <div className={s.objects_wrapper}>
                    <ObjectItem getObjectInfo={getObjectInfo} objectType={objectType} objects={objects} />
                </div>
            </div>
            {response.total > response.per_page ? 
                <Pagination
                totalItemsCount={response.total}
                activePage={response.current_page}
                itemsCountPerPage={response.per_page}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                hideFirstLastPages={true}
                prevPageText="‹"
                nextPageText="›"
                /> :
                <></>
            }
            </>
        );
    } 
  
}

export default Content;
