import React, {Component} from 'react';
import Logo from './logo/Logo';
import HeaderFilter from './header_filter/HeaderFilter';


import s from './Header.module.css';

class Header extends Component {

    state = {
        logoSrc: 'https://realdev.insidergroup.pro/storage/images/private_house/1571471277pCD9z7G9Ma5J4eBwBPeA3BE1iobkaU.png',
    }

    componentDidMount() {
        
    }

    render() {
        const {logoSrc} = this.state;
        const {objectID, sort, sortBy, handleSort, handleObjID} = this.props;

        return (
            <header className={s.header}>
                <Logo src={logoSrc}/>
                <HeaderFilter objectID={objectID} sortBy={sortBy} sort={sort} handleSort={handleSort} handleObjID={handleObjID}/>
            </header>
        );
    } 
  
}

export default Header;