import React, {Component} from 'react';
import {Animated} from 'react-animated-css';
import ObjectType  from './object_type/ObjectType';
import FlatForm from './flat_filter/flatForm';
import PrivateForm from './private_filter/PrivateForm';
import LandForm from './land_filter/LandForm';
import CommerceForm from './commerce_filter/CommerceForm';
import MainAddress from './address_filters/MainAddress';
import SubAddress from './address_filters/SubAddress';

import s from './FilterForm.module.css';

class FilterForm extends Component {

    state = {

    }

    render() {

        const {filterOpen, getObjType, objectType,getMainAddress,selectedRegionName,selectedAreaName,selectedCityName,city_id,getSubAddress} = this.props;

        return (

            <Animated className={ filterOpen ? `${s.opened} ${s.form_wrapper}` : `${s.form_wrapper}` } animationInDuration={500} animationOutDuration={100} animationInDelay={500} animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut" isVisible={filterOpen}>
                <ObjectType objectType={objectType} getObjType={getObjType}/>
                <MainAddress 
                    selectedRegionName={selectedRegionName} 
                    selectedAreaName={selectedAreaName}
                    selectedCityName={selectedCityName}
                    getMainAddress={getMainAddress}/>
                <SubAddress
                    city_id={city_id}
                    getSubAddress={getSubAddress} />
                {objectType === 'flat' ? 
                    <FlatForm {...this.props} /> :
                objectType === 'private-house' ?
                    <PrivateForm {...this.props} /> :
                objectType === 'land' ? 
                    <LandForm {...this.props} /> :
                    <CommerceForm {...this.props} /> 
                }
            </Animated>
            
        );
    } 
  
}

export default FilterForm;