import React,{Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HomePage from './components/HomePage';
import ObjectPage from './components/ObjectPage';

class App extends Component {

  state = {
    objectType: '',
    objectID: '',
  }

  getObjectInfo = (e) => {
    const{objectType, id} = e.target.dataset;

   this.setState({
     objectType: objectType,
     objectID: id,
   })

  }


  render() {

    const {objectType, objectID} = this.state;

    return(
      <BrowserRouter>
        <Switch>
          <Route 
          exact 
          path="/" 
          render={(props) => <HomePage {...props} getObjectInfo={this.getObjectInfo} />} />
          <Route
          path="/:type/:id" 
          render={(props) => <ObjectPage {...props} objectType={objectType} objectID={objectID} />} />
        </Switch>
      </BrowserRouter>
    )
  }

}


export default App;
