import React, {Component} from 'react';
import SortInput from './sort_input/SortInput';

import s from './HeaderFilter.module.css';

class HeaderFilter extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            objectID: '',
        }

    }


    handleChange = ({target: {value}}) => {
        this.setState({
          objectID: value,
        }) 
    }

    componentDidUpdate(prevProps) {

        const {objectID} = this.state;

        if(prevProps.objectID.length !== 0 && objectID !== this.props.objectID && objectID.length !== 0 ) {
            this.setState({
                objectID: '',
            })
        }
    }

    render() {
        const {objectID} = this.state;
        const {handleSort, handleObjID,  sort, sortBy} = this.props;

        return (
            <div className={s.header_filter}>
                <div className={s.object_id}>
                    <input ref={this.objectIDRef} type="text" name="object_id" id="object_id" value={objectID} onBlur={handleObjID} onChange={this.handleChange} placeholder="ID" />
                </div>
                <SortInput sort={sort} sortBy={sortBy} handleSort={handleSort}/>
            </div>
        );
    } 
  
}

export default HeaderFilter;