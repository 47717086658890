import React, {Component} from 'react';

import s from './ObjectItem.module.css';
import { NavLink } from 'react-router-dom';

class ObjectItem extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            result: this.props.objects,
            loading: true
        }
    }

    removeLoader = () => {
        setTimeout(() =>{
            this.setState({
                loading:false
            })
        },1000)
    }

    componentDidMount() {
        
        this.removeLoader();

    }

    componentDidUpdate(prevProps) {
        const {objectType, objects} = this.props;
        if(prevProps.objectType !== objectType) {
            this.setState({
                loading: true
            })
        }
        if(prevProps.objects !== objects) {
            this.removeLoader();
        }
    }

    render() {

        const {objectType, objects, getObjectInfo} = this.props;
        const {loading} = this.state;
        let imgWeb = []; 

        return (
            <> 
                <div
                  className={`lds_ring ${!loading ? `custom_hidden` : '' }`}
                  >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                </div>

                { objects !== undefined && objects[0] !== undefined ? 
                <>
                {objects.map((object) => 
                    <div key={object.id} className={`${s.object_item} ' col-12 col-md-6 col-xl-4 '` }>
                        <div className={s.card_content}>
                            <div className={s.card_image}>
                                
                                <img 
                                src={
                                    imgWeb = [],
                                    object.images !== undefined && object.images !== null && object.images.length !== 0  ? object.images.map((item) => {if(item.toSite){ if(item.toSite === '1') imgWeb.push(item);}}) : [],
                                        imgWeb.length !== 0 ?
                                        `${process.env.REACT_APP_ASSET_URL}/${imgWeb[0].url}` :
                                        `${process.env.REACT_APP_ASSET_URL}${process.env.REACT_APP_FALLBACK_IMAGE}`
                                }
                                alt="Object"/>
                                <div className={s.image_label}>
                                    <span>ID {object.id}</span>
                                </div>
                                <div className={s.image_info}>
                                    <div className={s.info_files}>
                                        <span className={s.photo}>
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                            { object.images !== undefined && object.images !== null ?
                                                imgWeb.length :
                                                <></>
                                            }
                                        </span>
                                        <span className={s.video}>
                                            {object.video !== '' && object.video !== null ? 
                                                <i className="fas fa-video"></i>  :
                                                <></>
                                            }
                                        </span>
                                    </div>
                                    <div className={s.complex_name}>
                                        {
                                            object.building.name_hc !== '' && object.building.name_hc !== null ?
                                                <span>{object.building.name_hc}</span> :
                                            <></> 
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={s.card_info}>
                                <div className={s.upper_info}>
                                    <div className={s.address}>
                                        <p className={s.upper_address}>
                                            {`${object.address.street}${object.address.house_number !== null && object.address.house_number.length !== 0  ? ', №' + object.address.house_number : ''}${object.address.section !== null && object.address.section.length !== 0 ? ', корпус ' + object.address.section : ''}`}
                                        </p>
                                        <p className={s.lower_address}>
                                            {
                                                object.address.district !== null ?
                                                `${object.address.district}${object.address.district.length !== 0 ? ', ' : '' }` :
                                                <></>
                                            }
                                            {
                                                object.address.microarea !== null ?
                                                `${object.address.microarea}${object.address.microarea.length !== 0 ? ', ' : '' }` :
                                                <></>
                                            }
                                            {
                                                object.address.city !== null ?
                                                   'м.' + object.address.city :
                                                <></>
                                            }
                                        </p>
                                    </div>
                                    <div className={s.price}>
                                        <p className={s.main_price}>
                                            {object.price.price !== 0 && object.price.price !== null ? object.price.price : '' } 
                                            { object.price.currency === 'EUR' ? '€' : object.price.currency === 'USD' ? '$' : object.price.currency === 'UAH' ? '₴' : '' }
                                        </p>
                                        <p className={s.price_for_meter}>
                                            {object.price_for_meter !== 0 && object.price_for_meter !== null ? object.price_for_meter + ' за м2' : '' }
                                        </p>
                                    </div>
                                </div>
                                <div className={s.lower_info}>
                                    <div className={s.object_part}>
                                        {   
                                            objectType === 'flat' && object.attributes.rooms_count !== 0 && object.attributes.rooms_count !== null ? 
                                                <p className={s.rooms}> {object.attributes.rooms_count} ком. <br/> {object.attributes.layout} </p> :
                                            objectType === 'private-house' && object.attributes.rooms_count !== 0 && object.attributes.rooms_count !== null ?
                                                <p className={s.rooms}> {object.attributes.rooms_count} ком. </p> :
                                            objectType === 'commerce' && object.attributes.rooms_count !== 0 && object.attributes.rooms_count !== null ?
                                                <p className={s.rooms}> {object.attributes.rooms_count} ком. </p> :
                                            <></>
                                        }
                                        {   
                                            objectType === 'flat' && object.attributes.floor !== 0 && object.attributes.floor !== null ? 
                                                <p className={s.floor}> {object.attributes.floor} этаж из {object.attributes.max_floor}</p>  :
                                            objectType === 'private-house' && object.attributes.floor !== 0 && object.attributes.floor !== null ?
                                                <p className={s.floor}>{object.attributes.floor} этажей</p> :
                                            objectType === 'commerce' && object.attributes.floor !== 0 && object.attributes.floor !== null ?
                                                <p className={s.floor}>{object.attributes.floor} этаж из {object.attributes.max_floor}</p> :
                                            <></>
                                        }
                                        {   
                                            objectType === 'flat' && object.attributes.area.total !== 0 && object.attributes.area.total !== null ? 
                                                <p className={s.square}>{object.attributes.area.total} / {object.attributes.area.living} / {object.attributes.area.kitchen} м<sup>2</sup></p>  :
                                            objectType === 'private-house' && object.attributes.area.total !== 0 && object.attributes.area.total !== null  ?
                                                <p className={s.square}>{object.attributes.area.total} / {object.attributes.area.living } / {object.attributes.area.kitchen} м<sup>2</sup></p> :
                                            objectType === 'land' && object.attributes.area.land_plot !== 0 && object.attributes.area.land_plot !== null  ?
                                                <p className={s.square}> {object.attributes.area.land_plot} м<sup>2</sup></p> :
                                            objectType === "commerce" && object.attributes.area.total !== 0 && object.attributes.area.total !== null ? 
                                                <p className={s.square}>{object.attributes.area.total} / {object.attributes.area.effective}  м<sup>2</sup></p> :
                                            <></>
                                        }
                                    </div>
                                    <div className={s.complex_part}>
                                        {   
                                            objectType === 'flat' ? 
                                            <div className={s.cond}>
                                                <span></span>
                                            </div>  :
                                            objectType === 'private-house' ?
                                                <div className={s.cond}>
                                                    <span>{object.land_plot.location}</span> 
                                                </div> :
                                            objectType === 'land' ?
                                                <div className={s.cond}>
                                                    <span>{object.land_plot.location}</span> 
                                                </div> :
                                            objectType === 'commerce' ? 
                                                <div className={s.cond}>
                                                    <span>{object.attributes.office_type}</span> 
                                                </div> :
                                            <></>
                                        }
                                        {   
                                            objectType === 'flat' ? 
                                                <div className={s.project}>
                                                    <span>{object.building.type}</span>
                                                </div>  :
                                            objectType === 'private-house' && object.land_plot.area !== undefined ?
                                                <div className={s.project}>
                                                    <span>Участок, {object.land_plot.area.value} {object.land_plot.area.unit} </span>
                                                </div> :
                                            objectType === 'commerce' ? 
                                                <div className={s.project}>
                                                    <span>Офис</span>
                                                </div> :
                                            <></>
                                        }
                                    </div>
                                    <div className={s.card_button}>
                                        {   
                                            objectType === 'flat' ? 
                                            <div className={s.card_free}>
                                                {
                                                    object.rent.release_date !== null && object.rent.release_date !== '' ?
                                                    <span className={s.free_date}>
                                                        {
                                                            object.rent.release_date.slice(8,10) + '.' + object.rent.release_date.slice(5,7) + '.' + object.rent.release_date.slice(0,4)
                                                        }
                                                    </span> :
                                                    <></>
                                                }
                                                {
                                                    object.rent.price !== null && object.rent.price !== '' ? 
                                                    <span className={s.free_price}>
                                                        {object.rent.price} {object.rent.currency}
                                                    </span> :
                                                    <></>
                                                }
                                            </div>  :
                                            objectType === 'commerce' ?
                                                <div className={s.card_free}>
                                                    {
                                                    object.rent.release_date !== null && object.rent.release_date !== '' ?
                                                        <span className={s.free_date}>
                                                            {
                                                                object.rent.release_date.slice(8,10) + '.' + object.rent.release_date.slice(5,7) + '.' + object.rent.release_date.slice(0,4)
                                                            }
                                                        </span> :
                                                        <></>
                                                    }
                                                    {
                                                        object.rent.price !== null && object.rent.price !== '' ? 
                                                        <span className={s.free_price}>
                                                            {object.rent.price} {object.rent.currency}
                                                        </span> :
                                                        <></>
                                                    }
                                                </div> :
                                            <></>
                                        }
                                        <NavLink onClick={getObjectInfo} data-object-type={objectType} data-id={object.id}  to={`/${objectType}/id${object.id}`} >
                                            Подробнее >>
                                        </NavLink> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                </>

                : 

                <p></p>

                }
            </>
            
        );
    } 
  
}

export default ObjectItem;