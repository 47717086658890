import React, {Component} from 'react';

import s from './ObjectType.module.css';

class ObjectType extends Component {

    render() {

        const {getObjType} = this.props;


        return (
            <div className={s.filter_obj}>
                <label className={s.obj_item}>
                    <input onChange={getObjType} id="flat" checked={this.props.objectType === 'flat'} name="object_type_radio" type="radio"/>
                    <span>Квартиры</span>
                </label>
                <label className={s.obj_item}>
                    <input onChange={getObjType} id="private-house" checked={this.props.objectType === 'private-house'} name="object_type_radio" type="radio"/>
                    <span>Частный <br/> дом</span>
                </label>
                <label className={s.obj_item}>
                    <input onChange={getObjType} id="land" checked={this.props.objectType === 'land'} name="object_type_radio" type="radio"/>
                    <span>Земельный <br/> участок</span>
                </label>
                <label className={s.obj_item}>
                    <input onChange={getObjType} id="commerce" checked={this.props.objectType === 'commerce'} name="object_type_radio" type="radio"/>
                    <span>Коммерция</span>
                </label>
            </div>
        );
    } 
  
}

export default ObjectType;