import React,{Component} from 'react';
import s from './ObjectPage.module.css';
import ObjectInfoItem from './ObjectInfoItem';
import { NavLink } from 'react-router-dom';

class ObjectPage extends Component {
    
    state = {
        objectType: '',
        objectID : '',
        object: undefined,
    }

    componentDidMount() {
       
        const {objectType,objectID,match} = this.props;

        if(objectType === '' && objectID === '' ) {
            let type = match.params.type;
            let id = match.params.id.slice(2);
            this.setState({
                objectType: type,
                objectID: id
            },() => {
                const {objectType,objectID} = this.state;
                const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects/${objectType}/${objectID}`);

                fetch(url, {
                    headers: new Headers({
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                    }),
                }).then(res => res.json())
                .then(response => this.setResponse(response))
                .catch(error => console.error('Error:', error));
            });
        } else {
            this.setState({
                objectType: objectType,
                objectID: objectID,
            }, () => {
                const {objectType,objectID} = this.state;
                const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects/${objectType}/${objectID}`);

                fetch(url, {
                    headers: new Headers({
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
                    }),
                }).then(res => res.json())
                .then(response => this.setResponse(response))
                .catch(error => console.error('Error:', error));
            })
        }

    }

    setResponse = response => {
        console.log(response);
        this.setState({
            object: response,
        })
    }

    changeImage = (e) => {
        const {src} = e.target;

        document.getElementById('main_image').src=src;

        let images = Array.from(e.target.parentNode.parentNode.querySelectorAll('div'));

        images.map((img) => {
            img.classList.remove(`${s.active}`);
        })        

        e.target.parentNode.classList.add(`${s.active}`);

    }

    componentDidUpdate() {

        let firstSubImage = document.getElementById("sub_images");


        if(firstSubImage !== null && firstSubImage !== undefined && firstSubImage.firstChild !== null ) {
            firstSubImage.firstChild.classList.add(`${s.active}`);
        }

    }

    render() {

        const{objectType} = this.state;
        const {object} = this.state;
        console.log(object);
        let imgWeb2 = [];


        if (object !== undefined) {
            object.images.forEach(image => {
                if(image.toSite === '1') {
                    imgWeb2.push(image);
                }
            });
        }

        return(

            <> 

            {object !== undefined ? 
            
                <div className="custom_container">
                    <div className={s.single_content}>
                        <div className={`${s.single_address} col-12 order-1 col-md-6 order-md-1 col-lg-4 order-lg-1 `}>
                            <div className={s.upper_address}>
                                <p>
                                    <span>ID {object.id}</span>
                                    {`${object.address.street}${object.address.house_number !== null && object.address.house_number.length !== 0  ? ', №' + object.address.house_number : ''}${object.address.section !== null && object.address.section.length !== 0 ? ', корпус ' + object.address.section : ''}`}
                                </p>
                            </div>
                            <div className={s.lower_address}>
                                <p>
                                {
                                    object.address.district !== null ?
                                    `${object.address.district}${object.address.district.length !== 0 ? ', ' : '' }` :
                                    <></>
                                }
                                {
                                    object.address.microarea !== null ?
                                    `${object.address.microarea}${object.address.microarea.length !== 0 ? ', ' : '' }` :
                                    <></>
                                }
                                {
                                    object.address.city !== null ?
                                        'м.' + object.address.city :
                                    <></>
                                }
                                </p>
                            </div>
                        </div>
                        <div className={`${s.single_slider} col-12 order-2 col-md-6 order-md-3 col-lg-4 order-lg-3 `}>
                            <div className={s.main_image}>
                                <img id="main_image" 
                                src={
                                    imgWeb2.length !== 0 ?
                                    `${process.env.REACT_APP_ASSET_URL}/${imgWeb2[0].url}` :
                                    `${process.env.REACT_APP_ASSET_URL}${process.env.REACT_APP_FALLBACK_IMAGE}`
                                } 
                                alt="#"/>
                            </div>
                            <div className={`${s.sub_images} col-12`} id="sub_images">
                                {imgWeb2.map((image,index) => 
                                    <div key={`image_${index}`} className={`${s.sub_image}`}>
                                        <img onClick={this.changeImage} src={`${process.env.REACT_APP_ASSET_URL}/${image.url}`} alt="#"/>
                                    </div>,
                                )}
                            </div>
                        </div>
                        <div className={`${s.price_contact} col-12 order-3 col-md-6 order-md-2 col-lg-4 order-lg-2 offset-lg-4 `}>
                            <div className={s.single_price}>
                                <p className={s.total_price}>
                                    <span>{object.price.price}</span>
                                    { object.price.currency === 'EUR' ? '€' : object.price.currency === 'USD' ? '$' : object.price.currency === 'UAH' ? '₴' : '' }
                                </p>
                                <p className={s.price_for_meter}>
                                    <span>{object.price_for_meter}</span>
                                    { object.price.currency === 'EUR' ? '€' : object.price.currency === 'USD' ? '$' : object.price.currency === 'UAH' ? '₴' : '' } за м<sup>2</sup>
                                </p>
                            </div>
                            <div className={s.contacts}>
                                <p className={s.auth}>
                                    {object.responsible.name}
                                </p>
                                <p className={s.number}>
                                    {object.responsible.phone}
                                </p>
                            </div>
                            <a className={s.single_call} href={`tel:${object.responsible.phone}`}>
                                позвонить
                            </a>
                        </div>
                        {
                            objectType === 'flat' ? 
                            <>
                            <div className={`${s.info_block} ${s.flats} col-12 order-4 col-md-6 order-md-4 col-lg-4 order-lg-4 `}>
                                <h4 className={s.info_title}>
                                    Квартира
                                </h4>
                                <div className={s.info_body}>
                                    <ObjectInfoItem title={`Комнат`} noInfo={object.attributes.rooms_count === null} value={`${object.attributes.rooms_count} ${object.attributes.layout !== null && object.attributes.layout !== '' ? object.attributes.layout : 'Нет информации'} `} />
                                    <ObjectInfoItem title={`Площадь (общая/жилая/кухня), м²`} noInfo={object.attributes.area.total === null} value={`${object.attributes.area.total} / ${object.attributes.area.living} / ${object.attributes.area.kitchen} `} />
                                    <ObjectInfoItem title={`Этаж`} noInfo={object.attributes.floor === null} value={`${object.attributes.floor} / ${object.attributes.max_floor}`} />
                                    <ObjectInfoItem title={`Ремонт`} noInfo={object.attributes.condition === null} value={object.attributes.condition} />
                                    <ObjectInfoItem title={`Санузел`} noInfo={object.attributes.bathroom === null} value={object.attributes.bathroom} />
                                    <ObjectInfoItem title={`Тип санузел`} noInfo={object.attributes.bathroom_type === null} value={object.attributes.bathroom_type} />
                                    <ObjectInfoItem title={`Количество санузлов`} noInfo={object.attributes.bathroom_count === null} value={object.attributes.bathroom_count} />
                                    <ObjectInfoItem title={`Цокольный этаж`} noInfo={object.attributes.ground_floor === null} value={object.attributes.ground_floor === 0 ? 'Да' : 'Нет'} />
                                    <ObjectInfoItem title={`Окна / Столярка`} noInfo={object.attributes.carpentry === null} value={object.attributes.carpentry} />
                                    <ObjectInfoItem title={`Балкон`} noInfo={object.attributes.balcon === null} value={object.attributes.balcon} />
                                    <ObjectInfoItem title={`Тип остекления балкона`} noInfo={object.attributes.balcon_glazing_type === null} value={object.attributes.balcon_glazing_type} />
                                    <ObjectInfoItem title={`Состояние балкона`} noInfo={object.attributes.balcon_equipment === null} value={object.attributes.balcon_equipment} />
                                    <ObjectInfoItem title={`Отопление`} noInfo={object.attributes.heating === null} value={object.attributes.heating} />
                                    <ObjectInfoItem title={`Сторона света`} noInfo={object.attributes.worldsides === null || object.attributes.worldsides === ''} value={
                                        object.attributes.worldsides !== null ?
                                        object.attributes.worldsides.map((side,index) => 
                                            <p className={s.world_side} key={`side_${index}`}>
                                                <span>{side}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                                    <ObjectInfoItem title={`Вид на`} noInfo={object.attributes.view === null} value={object.attributes.view} />
                                </div>
                            </div>
                            <div className={`${s.info_block} ${s.house} col-12 order-5 col-md-6 order-md-6 col-lg-4 order-lg-5 `}>
                                <h4 className={s.info_title}>Дом</h4>
                                <div className={s.info_body}>
                                    <ObjectInfoItem title={`Тип жилья`} noInfo={object.building.type === null} value={object.building.type} />
                                    <ObjectInfoItem title={`Класс дома`} noInfo={object.building.class === null} value={object.building.class} />
                                    <ObjectInfoItem title={`Этажность`} noInfo={object.building.floors === null} value={object.building.floors} />
                                    <ObjectInfoItem title={`Материал постройки`} noInfo={object.building.material === null} value={object.building.material} />
                                    <ObjectInfoItem title={`Перекрытие`} noInfo={object.building.overlap === null} value={object.building.overlap} />
                                    <ObjectInfoItem title={`Подъезд к дому`} noInfo={object.building.way === null} value={object.building.way} />
                                    <ObjectInfoItem title={`Высота потолка, м`} noInfo={object.building.ceiling_height === null} value={object.building.ceiling_height} />
                                    <ObjectInfoItem title={`Лифт`} noInfo={object.building.passenger_lift === 0 && object.building.service_lift === 0} value={`${object.building.passenger_lift === 1 ? 'Пассажирский' : ''}  ${object.building.service_lift === 1 ? 'Грузовой' : ''}`} />
                                    <ObjectInfoItem title={`Год постройки`} noInfo={object.building.year_build === null} value={object.building.year_build} />
                                    <ObjectInfoItem title={`Застройщик`} noInfo={object.building.builder === null} value={object.building.builder} />
                                </div>
                            </div> 
                        </>
                        :
                        objectType === 'private-house' ?
                        <>
                        <div className={`${s.info_block} ${s.flats} col-12 order-4 col-md-6 order-md-4 col-lg-4 order-lg-4 `}>
                            <h4 className={s.info_title}>
                                Частный дом
                            </h4>
                            <div className={s.info_body}>
                                <ObjectInfoItem title={`Свободен с`} noInfo={object.building.date_release === null} value={ object.building.date_release !== null ? `${object.building.date_release.slice(8,10)}.${object.building.date_release.slice(5,7)}.${object.building.date_release.slice(0,4)}` : '' } />
                                <ObjectInfoItem title={`Комнат`} noInfo={object.attributes.rooms_count === null} value={object.attributes.rooms_count} />
                                <ObjectInfoItem title={`Площадь (общая/жилая/кухня), м²`} noInfo={object.attributes.area.total === null} value={`${object.attributes.area.total} / ${object.attributes.area.living === null ? '' : object.attributes.area.living} / ${object.attributes.area.kitchen === null ? '' : object.attributes.area.kitchen}`} />
                                <ObjectInfoItem title={`Этажность`} noInfo={object.building.floors === null} value={object.building.floors} />
                                <ObjectInfoItem title={`Ремонт`} noInfo={object.attributes.condition === null} value={object.attributes.condition} />
                                <ObjectInfoItem title={`Санузел`} noInfo={object.attributes.bathroom === null} value={object.attributes.bathroom} />
                                <ObjectInfoItem title={`Тип санузел`} noInfo={object.attributes.bathroom_type === null} value={object.attributes.bathroom_type} />
                                <ObjectInfoItem title={`Количество санузлов`} noInfo={object.attributes.bathroom_count === null} value={object.attributes.bathroom_count} />
                                <ObjectInfoItem title={`Цокольный этаж`} noInfo={object.building.tech_floor === null} value={object.building.tech_floor === 1 ? 'Да' : 'Нет'} />
                                <ObjectInfoItem title={`Окна / Столярка`} noInfo={object.attributes.carpentry === null} value={object.attributes.carpentry} />
                                <ObjectInfoItem title={`Балкон`} noInfo={object.attributes.balcon === null} value={object.attributes.balcon} />
                                <ObjectInfoItem title={`Тип остекления балкона`} noInfo={object.attributes.balcon_glazing_type === null} value={object.attributes.balcon_glazing_type} />
                                <ObjectInfoItem title={`Состояние балкона`} noInfo={object.attributes.balcon_equipment === null} value={object.attributes.balcon_equipment} />
                                <ObjectInfoItem title={`Отопление`} noInfo={object.attributes.heating === null} value={object.attributes.heating} />
                                <ObjectInfoItem title={`Сторона света`} noInfo={object.attributes.worldsides === null || object.attributes.worldsides === ''} value={
                                        object.attributes.worldsides !== null ?
                                        object.attributes.worldsides.map((side,index) => 
                                            <p className={s.world_side} key={`side_${index}`}>
                                                <span>{side}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                                <ObjectInfoItem title={`Вид на`} noInfo={object.attributes.view === null} value={object.attributes.view} />
                            </div>
                        </div>
                        <div className={`${s.info_block} ${s.house} col-12 order-5 col-md-6 order-md-6 col-lg-4 order-lg-5 `}>
                            <h4 className={s.info_title}>Земельный участок</h4>
                            <div className={s.info_body}>
                                <ObjectInfoItem title={`Форма участка`} noInfo={object.land_plot.form === null} value={object.land_plot.form} />
                                <ObjectInfoItem title={`Площадь участка, сот.`} noInfo={object.land_plot.area.value === null || object.land_plot.area.value === ''} value={object.land_plot.area.value} />
                                <ObjectInfoItem title={`Кадастровый номер`} noInfo={object.land_plot.cadastral_card === null} value={object.land_plot.cadastral_card} />
                                <ObjectInfoItem title={`Приватизирован`} noInfo={object.land_plot.privatization === null} value={object.land_plot.privatization} />
                                <ObjectInfoItem title={`Расположение участка`} noInfo={object.land_plot.location === null} value={object.land_plot.location} />
                                <ObjectInfoItem title={`Целевое назначение`} noInfo={object.land_plot.purpose === null} value={object.land_plot.purpose} />
                                <ObjectInfoItem multi={true} title={`Коммуникации`} noInfo={object.land_plot.communications === null || object.land_plot.communications === ''} value={
                                        object.land_plot.communications !== null ?
                                        object.land_plot.communications.map((item,index) => 
                                            <p className={s.world_side} key={`comm_${index}`}>
                                                <span>{item}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                                <ObjectInfoItem multi={true} title={`На участке`} noInfo={object.land_plot.territory === null || object.land_plot.territory === ''} value={
                                        object.land_plot.territory !== null ?
                                        object.land_plot.territory.map((item,index) => 
                                            <p className={s.world_side} key={`terr_${index}`}>
                                                <span>{item}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                            </div>
                        </div>
                        </>
                        :
                        objectType === 'land' ? 
                        <>
                        <div className={`${s.info_block} ${s.house} col-12 order-5 col-md-6 order-md-6 col-lg-4 order-lg-5 `}>
                            <h4 className={s.info_title}>Земельный участок</h4>
                            <div className={s.info_body}>
                                <ObjectInfoItem title={`Форма участка`} noInfo={object.land_plot.form === null} value={object.land_plot.form} />
                                <ObjectInfoItem title={`Площадь участка, сот.`} noInfo={object.land_plot.area.value === null || object.land_plot.area.value === ''} value={object.land_plot.area.value} />
                                <ObjectInfoItem title={`Кадастровый номер`} noInfo={object.land_plot.cadastral_card === null} value={object.land_plot.cadastral_card} />
                                <ObjectInfoItem title={`Приватизирован`} noInfo={object.land_plot.privatization === null} value={object.land_plot.privatization} />
                                <ObjectInfoItem title={`Расположение участка`} noInfo={object.land_plot.location === null} value={object.land_plot.location} />
                                <ObjectInfoItem title={`Целевое назначение`} noInfo={object.land_plot.purpose === null} value={object.land_plot.purpose} />
                                <ObjectInfoItem multi={true} title={`Коммуникации`} noInfo={object.land_plot.communications === null || object.land_plot.communications === ''} value={
                                        object.land_plot.communications !== null ?
                                        object.land_plot.communications.map((item,index) => 
                                            <p className={s.world_side} key={`comm_${index}`}>
                                                <span>{item}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                                <ObjectInfoItem multi={true} title={`На участке`} noInfo={object.land_plot.territory === null || object.land_plot.territory === ''} value={
                                        object.land_plot.territory !== null ?
                                        object.land_plot.territory.map((item,index) => 
                                            <p className={s.world_side} key={`terr_${index}`}>
                                                <span>{item}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                            </div>
                        </div> 
                        </>
                        :

                        objectType === 'commerce' ?
                        <>
                        <div className={`${s.info_block} ${s.flats} col-12 order-4 col-md-6 order-md-4 col-lg-4 order-lg-4 `}>
                            <h4 className={s.info_title}>
                                Комерческая недвижимость
                            </h4>
                            <div className={s.info_body}>
                                <ObjectInfoItem title={`Тип`} noInfo={object.building.type === null} value={object.building.type} />
                                <ObjectInfoItem title={`Тип помещения`} noInfo={object.attributes.office_type === null} value={object.attributes.office_type} />
                                <ObjectInfoItem title={`Свободен с`} noInfo={object.building.date_release === null} value={ object.building.date_release !== null ? `${object.building.date_release.slice(8,10)}.${object.building.date_release.slice(5,7)}.${object.building.date_release.slice(0,4)}` : '' } />
                                <ObjectInfoItem title={`Комнат`} noInfo={object.attributes.rooms_count === null} value={object.attributes.rooms_count} />
                                <ObjectInfoItem title={`Площадь (общая/жилая/кухня), м²`} noInfo={object.attributes.area.total === null} value={`${object.attributes.area.total} / ${object.attributes.area.living === null ? '' : object.attributes.area.living} / ${object.attributes.area.kitchen === null ? '' : object.attributes.area.kitchen}`} />
                                <ObjectInfoItem title={`Этаж`} noInfo={object.attributes.floor === null} value={`${object.attributes.floor} / ${object.attributes.max_floor}`} />
                                <ObjectInfoItem title={`Ремонт`} noInfo={object.attributes.condition === null} value={object.attributes.condition} />
                                <ObjectInfoItem title={`Санузел`} noInfo={object.attributes.bathroom === null} value={object.attributes.bathroom} />
                                <ObjectInfoItem title={`Тип санузел`} noInfo={object.attributes.bathroom_type === null} value={object.attributes.bathroom_type} />
                                <ObjectInfoItem title={`Количество санузлов`} noInfo={object.attributes.bathroom_count === null} value={object.attributes.bathroom_count} />
                                <ObjectInfoItem title={`Цокольный этаж`} noInfo={object.building.tech_floor === null} value={object.building.tech_floor === 1 ? 'Да' : 'Нет'} />
                                <ObjectInfoItem title={`Окна / Столярка`} noInfo={object.attributes.carpentry === null} value={object.attributes.carpentry} />
                                <ObjectInfoItem title={`Балкон`} noInfo={object.attributes.balcon === null} value={object.attributes.balcon} />
                                <ObjectInfoItem title={`Тип остекления балкона`} noInfo={object.attributes.balcon_glazing_type === null} value={object.attributes.balcon_glazing_type} />
                                <ObjectInfoItem title={`Состояние балкона`} noInfo={object.attributes.balcon_equipment === null} value={object.attributes.balcon_equipment} />
                                <ObjectInfoItem title={`Отопление`} noInfo={object.attributes.heating === null} value={object.attributes.heating} />
                                <ObjectInfoItem title={`Сторона света`} noInfo={object.attributes.worldsides === null || object.attributes.worldsides === ''} value={
                                        object.attributes.worldsides !== null ?
                                        object.attributes.worldsides.map((side,index) => 
                                            <p className={s.world_side} key={`side_${index}`}>
                                                <span>{side}</span>
                                                <span className={s.coma}>,</span> 
                                            </p>          
                                        ) :
                                        <></>
                                    } />
                                <ObjectInfoItem title={`Вид на`} noInfo={object.attributes.view === null} value={object.attributes.view} />
                            </div>
                        </div>
                        <div className={`${s.info_block} ${s.house} col-12 order-5 col-md-6 order-md-6 col-lg-4 order-lg-5 `}>
                            <h4 className={s.info_title}>Дом</h4>
                            <div className={s.info_body}>
                                <ObjectInfoItem title={`Название ТЦ, БЦ`} noInfo={object.building.name_bc === null} value={object.building.name_bc} />
                                <ObjectInfoItem title={`Тип жилья`} noInfo={object.building.type === null} value={object.building.type} />
                                <ObjectInfoItem title={`Класс дома`} noInfo={object.building.class === null} value={object.building.class} />
                                <ObjectInfoItem title={`Этажность`} noInfo={object.building.floors === null} value={object.building.floors} />
                                <ObjectInfoItem title={`Материал постройки`} noInfo={object.building.material === null} value={object.building.material} />
                                <ObjectInfoItem title={`Перекрытие`} noInfo={object.building.overlap === null} value={object.building.overlap} />
                                <ObjectInfoItem title={`Подъезд к дому`} noInfo={object.building.way === null} value={object.building.way} />
                                <ObjectInfoItem title={`Высота потолка, м`} noInfo={object.building.ceiling_height === null} value={object.building.ceiling_height} />
                                <ObjectInfoItem title={`Лифт`} noInfo={object.building.passenger_lift === 0 && object.building.service_lift === 0} value={`${object.building.passenger_lift === 1 ? 'Пассажирский' : ''}  ${object.building.service_lift === 1 ? 'Грузовой' : ''}`} />
                                <ObjectInfoItem title={`Год постройки`} noInfo={object.building.year_build === null} value={object.building.year_build} />
                                <ObjectInfoItem title={`Застройщик`} noInfo={object.building.builder === null} value={object.building.builder} />
                            </div>
                        </div>
                        </>
                        :
                        <></>

                        }
                        
                        <div className={`${s.single_description} col-12 order-7 col-md-6 order-md-5 col-lg-4 order-lg-6 offset-lg-4`}>
                            <h4 className={s.descr_header}>{object.title}</h4>
                            <div className={s.descr_body}>
                                <span>ОПИСАНИЕ НА САЙТ</span>
                                {object.description}
                            </div>
                        </div>
                        {/* <div className={`${s.map_wrapper} col-12 order-7 `}>
                            <div id="mainMap"></div>
                        </div> */}
                        <div className={`${s.single_buttons} col-12 order-8 `}>
                            <NavLink className={`${s.single_button} ${s.back} `} to="/" >
                                {`<< Назад`}
                            </NavLink> 
                            <a className={`${s.single_button} ${s.call} `} href={`tel:${object.responsible.phone}`}>
                                ПОЗВОНИТЬ
                            </a>
                        </div>
                    </div>
                </div> 
                
                :

            <p className={s.load} >Загрузка...</p>

        }

        </>

        )

    }

}





export default ObjectPage;
